import { useState, useEffect } from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom"
import { SnackbarProvider } from "notistack"
import { withRoot } from "./withRoot"
import theme from "./config/theme"
import Home from "./pages/Home"
import Promo from "./pages/Promo"
import './App.css';
import './custom-font.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
            <Router>
              <CssBaseline />
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/promo" element={<Promo />} />
                </Routes>
            </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withRoot(App)
