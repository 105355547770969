import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import * as React from "react";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1800,
  },
};
const typography = {
  //   htmlFontSize: 10, // 1 rem = 10px, 10/16 = 62.5%
  //   "@include respond(tab-land)": {
  //     // width < 1200?
  //     htmlFontSize: 9, //1 rem = 9px, 9/16 = 56.25%
  //   },
  //   "@include respond(tab-port)": {
  //     // width < 900?
  //     htmlFontSize: 8, //1 rem = 8px, 8/16 = 50%
  //   },
  //   "@include respond(big-desktop)": {
  //     htmlFontSize: 12, //1rem = 12, 12/16 = 75%
  //   },

  body1: {
    /// fontSize: "10px",
  },
  //   fontSize: 14,
  //   fontWeightLight: "normal",
  //   fontWeightMedium: "bold",
  //   fontWeightBold: "bolder",
  //   fontWeightRegular: "normal",
};
const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {},
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: {
        //height: "5.6rem",
        //background: "red",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "capitalize !important",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: "capitalize !important",
      },
      text: {
        textTransform: "none !important",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: { minHeight: "50px !important" },
    },
  },
};

const lightTheme = createTheme({
  breakpoints,
  typography,
  components,
});

const darkTheme = createTheme({
  breakpoints,
  typography,
  palette: {
    mode: "dark",
  },
  components,
});

export function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    const [darkMode, setDarkMode] = React.useState(false);

    return (
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} {...(darkMode ? darkTheme : lightTheme)} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}
