import { createTheme } from "@mui/material"

// Global MUI theme and style overrides

const theme = createTheme({
  palette: {
    primary: {
      main: '#d24b3b',
    },
  },
  typography: {
    fontFamily: '"MaestroArabic", "Cervo", sans-serif',
    button: {
      textTransform: "none",
      fontSize: 15,
      fontWeight: 700,
    },
  },
})

export default theme
