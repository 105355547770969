import {
    AppBar,
    Box,
    Button,
    Card, CardContent, CardMedia, 
    Typography,
  } from "@mui/material"
  import { useState } from "react"
  import { useEffect } from "react"
  import { Stack } from "@mui/system"
  import { useNavigate, useSearchParams } from "react-router-dom"
  import { makeStyles } from '@material-ui/core/styles';
  import promo1 from '../assets/promo1.gif';
  import promo2 from '../assets/promo2.gif';
  import promo3 from '../assets/promo3.gif';
  import promo4 from '../assets/promo4.gif';
  import promo5 from '../assets/promo5.gif';
  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#f7e8db',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    line: {
      marginBottom: theme.spacing(2),
    },
    card: {
      maxWidth: 500,
      margin: 'auto',
    },
    media: {
      width: 450,
      height: 250,
      margin: 'auto',
    },
  }));

  const Promo = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [promo, setPromo] = useState();
    const promo1Codes = [
      "مشاعل",
      "فاطمة",
      "دانية",
      "العنود",
      "الهنوف",
      "أمنية",
      "الجوهرة",
      "الجازي",
      "جمانة",
      "خديجة",
      "تغريد",
      "بشاير",
      "ابتسام",
      "جميلة",
      "منيرة",
      "عايشه",
    ]
    const promo2Codes = [
      "تهاني",
      "اماني",
      "لولوه",
      "منيرة",
      "وجدان",
      "اميرة",
      "انفال",
      "ايمان",
      "غيداء",
      "عزيزة",
      "الهام",
      "شيهانه",
      "خزامى",
      "تغريد",
      "رانيا",
      "ريتاج",
    ]
    const promo3Codes = [
      "جيهان",
      "شيماء",
      "داليا",
      "ريناد",
      "ياسمين",
      "أفنان",
      "افراح",
      "أشواق",
      "نوران",
      "حورية",
      "ريانه",
      "رودينا",
      "أسماء",
      "بدرية",
      "فوزية",
    ]
    const promo5Codes = [
      "عبدالله",
      "أحمد",
      "عبدالعزيز",
      "عبدالرحمن",
      "سعود",
      "وليد",
      "خالد",
      "ناصر",
      "معاذ",
      "محمد",
      "عمر",
      "طلال",
      "هاشم",
      "حسين",
      "حسن",
      "ركان",
      "فيصل",
      "مشاري",
      "مشعل",
      "تركي",
      "حمزة",
      "سليمان",
      "سلمان",
      "سالم",
      "ابراهيم",
      "سلطان",
      "فارس",
      "عبدالرحيم",
      "نايف",
      "نواف",
      "موسى",
      "أسامة",
      "ثامر",
      "ماهر",
      "فهد",
      "يوسف",
      "كريم",
      "مؤيد",
      "صالح",
      "ياسر",
      "بندر",
      "سعيد",
      "علي",
      "عصام",
      "حمد",
      "ماجد",
      "سعد",
      "صلاح",
      "عبدالاله",
    ]


  useEffect(() => {
    if (promo1Codes.includes(searchParams.get('name'))) {
      setPromo(1)
    } else if (promo2Codes.includes(searchParams.get('name'))) {
      setPromo(2)
    } else if (promo3Codes.includes(searchParams.get('name'))) {
      setPromo(4)
    } else if (promo5Codes.includes(searchParams.get('name'))) {
      setPromo(5)
    } else {
      setPromo(4)
    }
  }, [])

    return (
      <div className={classes.root}>
        {promo == 1 ? (<>
        <Card className={classes.card}>
          <CardMedia className={classes.media}>
            <img src={promo1} alt="My Image" />
          </CardMedia>
        </Card>
        <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#d24b3b' }}>! {searchParams.get('name')}</span>
      </Typography>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}>احتفلي بانجازاتك وطموحك مع من تحبين</span>
      </Typography>
      <Typography variant="h5" fontWeight={700}  className={classes.line}>
        <span style={{ color: '#1c5c50' }}>وخذي عرض </span>
        <span style={{ color: '#f8a838' }}>بيانو بيتزا و بوكس حفلة الدجاج مع بطاطس ودجز بـ 109 ريال</span>
      </Typography>
        </>) : promo == 2 ? (<>
        <Card className={classes.card}>
          <CardMedia className={classes.media}>
            <img src={promo2} alt="My Image" />
          </CardMedia>
        </Card>
        <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#d24b3b' }}>! {searchParams.get('name')}</span>
      </Typography>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}>جاك عرض 2 بيتزا كبيرة بـ27 ريال للواحدة اللي باسمك ...</span>
      </Typography>
      <Typography variant="h5" fontWeight={700}  className={classes.line}>
        <span style={{ color: '#1c5c50' }}>شاركي العرض اللي باسمك مع صديقاتك بالقروب واكشخي فييه</span>
        {/* <span style={{ color: '#f8a838' }}> 2 بيتزا كبيرة بـ 25 ريال للوحدة</span> */}
      </Typography>
        </>) : promo == 3 ? (<>
        <Card className={classes.card}>
          <CardMedia className={classes.media}>
            <img src={promo3} alt="My Image" />
          </CardMedia>
        </Card>
        <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#d24b3b' }}>! {searchParams.get('name')}</span>
      </Typography>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}>من اسمك واضح انك ذويقة وبهالمناسبة حابين</span>
      </Typography>
      <Typography variant="h5" fontWeight={700}  className={classes.line}>
        <span style={{ color: '#1c5c50' }}>نعرض عليك الموزريلا رولز ببلاش مع أي طلب </span>
        {/* <span style={{ color: '#f8a838' }}>توصيل طلبك ببلاش</span> */}
      </Typography>
        </>) : promo == 4 ? (<>
        <Card className={classes.card}>
          <CardMedia className={classes.media}>
            <img src={promo4} alt="My Image" />
          </CardMedia>
        </Card>
        <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#d24b3b' }}>! {searchParams.get('name')}</span>
      </Typography>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}>اسمك الرهيب مالقينا له عرض لكن</span>
      </Typography>
      <Typography variant="h5" fontWeight={700}  className={classes.line}>
        <span style={{ color: '#1c5c50' }}>البيتزا بـ 10 و 20  و 30 على تطبيقنا ولاتنسين تحتفلين بنفسك</span>
      </Typography>
        </>) : promo == 5 ? (<>
        <Card className={classes.card}>
          <CardMedia className={classes.media}>
            <img src={promo5} alt="My Image" />
          </CardMedia>
        </Card>
        <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#d24b3b' }}>! تم القفط</span>
      </Typography>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}>ماتمشي علينا حركاتك بس مانستغني عنك. استمتع باسعارنا 10 20 30 وعليك بالعافية</span>
      </Typography>
        </>) : (<>
        </>)}
      <Typography variant="body2" style={{marginTop: '20vh'}}  className={classes.line}>
        <span style={{ color: '#1c5c50' }}>طريقة تطبيق العرض: 
          *كتابة اسمك المعروض في خانه كود الخصم على تطبيق مايسترو بيتزا
          </span>
      </Typography>
      <Typography variant="body2" style={{marginBottom: '5vh'}}  className={classes.line}>
          <span style={{ color: '#1c5c50' }}>*العرض ساري فقط يوم 8 مارس</span>
      </Typography>
      </div>
    )
  }
  
  export default Promo
  