import {
    AppBar,
    Box,
    Button,
    Card,
    TextField,
    Toolbar,
    Typography,
  } from "@mui/material"
  import { useState } from "react"
  import { Stack } from "@mui/system"
  import { useNavigate } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import ButtonImage from '../assets/Button.png';
import { useSnackbar } from "notistack"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f7e8db',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  line: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      border: '2px solid red',
      fontWeight: 'bold',
    },
    '& .MuiInputBase-input': {
      width: '40vh',
    },
  },
}));


const styles = {
  button: {
    marginTop: '10vh',
    backgroundImage: `url(${ButtonImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
  },
};

  const Home = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState('');
  const navigate = useNavigate();

  function isArabicFemaleName(name) {
    const regex = /^[\u0621-\u064A]{2,}(?:[\s.]+[\u0621-\u064A]{2,})*[\u0640-\u0652]?(?:\s(?:[bntfmlkhsaeouiy]|[al]d|as|at|ul|il|wal|bin|bint|ibn|abu|umm))*$/;
    return regex.test(name);
  }
  const promo1Codes = [
    "مشاعل",
    "فاطمة",
    "دانية",
    "العنود",
    "الهنوف",
    "أمنية",
    "الجوهرة",
    "الجازي",
    "جمانة",
    "خديجة",
    "تغريد",
    "بشاير",
    "ابتسام",
    "جميلة",
    "منيرة",
    "عايشه",
  ]
  const promo2Codes = [
    "تهاني",
    "اماني",
    "لولوه",
    "منيرة",
    "وجدان",
    "اميرة",
    "انفال",
    "ايمان",
    "غيداء",
    "عزيزة",
    "الهام",
    "شيهانه",
    "خزامى",
    "تغريد",
    "رانيا",
    "ريتاج",
  ]
  const promo3Codes = [
    "جيهان",
    "شيماء",
    "داليا",
    "ريناد",
    "ياسمين",
    "أفنان",
    "افراح",
    "أشواق",
    "نوران",
    "حورية",
    "ريانه",
    "رودينا",
    "أسماء",
    "بدرية",
    "فوزية",
  ]
  const promo5Codes = [
    "عبدالله",
    "أحمد",
    "عبدالعزيز",
    "عبدالرحمن",
    "سعود",
    "وليد",
    "خالد",
    "ناصر",
    "معاذ",
    "محمد",
    "عمر",
    "طلال",
    "هاشم",
    "حسين",
    "حسن",
    "ركان",
    "فيصل",
    "مشاري",
    "مشعل",
    "تركي",
    "حمزة",
    "سليمان",
    "سلمان",
    "سالم",
    "ابراهيم",
    "سلطان",
    "فارس",
    "عبدالرحيم",
    "نايف",
    "نواف",
    "موسى",
    "أسامة",
    "ثامر",
    "ماهر",
    "فهد",
    "يوسف",
    "كريم",
    "مؤيد",
    "صالح",
    "ياسر",
    "بندر",
    "سعيد",
    "علي",
    "عصام",
    "حمد",
    "ماجد",
    "سعد",
    "صلاح",
    "عبدالاله",
  ]
  


  const handleChange = (e) => {
    const { name, value } = e.target
    setName(value);
  }
  const promo = async () => {
    if (name != null && name != '' && (promo1Codes.includes(name) || promo2Codes.includes(name) || promo3Codes.includes(name) || promo5Codes.includes(name) || isArabicFemaleName(name))) {
      navigate("/promo" + "?name=" + name)
    } else {
      enqueueSnackbar("الرجاء إدخال الاسم الصحيح", { variant: "error" })
    }
  }
    return (
       <div className={classes.root}>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}>...الى </span>
        <span style={{ color: '#d24b3b' }}>أهم قطعة </span>
        <span style={{ color: '#1c5c50' }}>بالمجتمع </span>
      </Typography>
      <Typography variant="h5" fontWeight={700} className={classes.line}>
        <span style={{ color: '#1c5c50' }}> مايسترو بيتزا</span>
        <span style={{ color: '#7e9c91' }}> يهنيك </span>
        <span style={{ color: '#f8a838' }}>ويحتفل فيك </span>
        <span style={{ color: '#1c5c50' }}>بهاليوم</span>
      </Typography>
      <Typography variant="h5" fontWeight={700} style={{marginTop: '5vh'}}  className={classes.line}>
        <span style={{ color: '#1c5c50' }}> :اكتبي اسمك وشوفي هديتنا لك</span>
      </Typography>
      <TextField
       style={{marginTop: '5vh'}}
        className={classes.textField}
        variant="outlined"
        onChange={handleChange}
      />
      <Button onClick={promo} variant="contained" color="primary" style={{marginTop: '10vh'}} className={classes.button}>
      هديتك
      </Button>
    </div>
    )
  }
  
  export default Home
  